import { USER_INFO, AUTH_DAC } from '../../constants/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import {
    ChainId,
    Env,
    Layer,
    TestnetTokenList,
    Token,
    TokenList,
} from '../../constants';
// import type { RootState } from '../index';

// Define a type for the slice state

interface TokenDetail {
    loading: boolean;
    balance: BigNumber;
    decimals: number;
    loadingOnce?: boolean;
}

interface Tokens {
    [key: string]: {
        [Layer.layer1]: TokenDetail;
        [Layer.layer2]: TokenDetail;
    };
}

interface TokensState {
    tokens: Tokens;
    tokenList: Token[];
    contract: {
        [key: number]: {
            [key: string]: any;
        };
    };
    contractInitFlag: boolean;
}

function initTokens(tokenList: Token[]) {
    const tokens: Tokens = {};
    for (let i = 0, len = tokenList.length; i < len; i++) {
        tokens[tokenList[i]] = {
            [Layer.layer1]: {
                loading: false,
                balance: new BigNumber(0),
                decimals: 0,
                loadingOnce: false,
            },
            [Layer.layer2]: {
                loading: false,
                balance: new BigNumber(0),
                decimals: 0,
                loadingOnce: false,
            },
        };
    }

    return tokens;
}

const isMainnet = Number(process.env.REACT_APP_IS_MAINNET) === 1;

const tempTokenList = isMainnet ? TokenList : TestnetTokenList;

// Define the initial state using that type
const initialState: TokensState = {
    tokenList: tempTokenList,
    tokens: initTokens(tempTokenList),
    contract: {},
    contractInitFlag: false,
};

export const tokensState = createSlice({
    name: 'tokens',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setTokenList: (state, action: PayloadAction<Token[]>) => {
            if (action.payload.length !== state.tokenList.length) {
                // env change
                state.tokenList = action.payload;
                state.tokens = initTokens(action.payload);
            }
        },
        updateTokenDetail: (
            state,
            action: PayloadAction<{
                name: Token;
                layer: Layer;
                detail: TokenDetail;
            }>,
        ) => {
            const { name, layer, detail } = action.payload;
            state.tokens[name][layer].balance = detail.balance;
            state.tokens[name][layer].loading = detail.loading;
            state.tokens[name][layer].decimals = detail.decimals;
        },
        updateTokenContract: (
            state,
            action: PayloadAction<{
                chainId: ChainId;
                tokenName: Token;
                contract: any;
            }>,
        ) => {
            const { chainId, tokenName, contract } = action.payload;
            if (state.contract[chainId] && state.contract[chainId][tokenName])
                return;
            if (state.contract[chainId]) {
                state.contract[chainId][tokenName] = contract;
            } else {
                state.contract[chainId] = {
                    [tokenName]: contract,
                };
            }
        },
        setTokenLoading: (
            state,
            action: PayloadAction<{
                name: Token;
                layer: Layer;
                loading: boolean;
            }>,
        ) => {
            const { name, layer, loading } = action.payload;
            state.tokens[name][layer].loading = loading;
            if (!state.tokens[name][layer].loadingOnce && loading) {
                state.tokens[name][layer].loadingOnce = loading;
            }
        },
        setToggleContractFlag: (state) => {
            state.contractInitFlag = !state.contractInitFlag;
        },
    },
});

export const {
    updateTokenDetail,
    setTokenLoading,
    setTokenList,
    updateTokenContract,
    setToggleContractFlag,
} = tokensState.actions;

export default tokensState.reducer;
