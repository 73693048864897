import React, { useState } from 'react';
import cx from 'classnames';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from 'components/Modal';
// import style from './index.module.scss';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Row from 'components/Row';
import useActivityShow from 'hooks/useActivityShow';
import SynapseSVG from 'assets/images/synapse_logo.svg';
import MobileFooterSVG from 'assets/images/activity/mobile-footer.svg';

const Wrapper = styled.div`
    margin: 0 auto 70px;
    width: 540px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `}
    .thirdPartyMetisSection {
        border-radius: 32px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        justify-content: flex-start;
        flex-direction: column;
        a {
            width: 100% !important;
            margin-top: 20px;
        }
        `}
        a {
            text-decoration: none;
            display: inline-block;
            width: 390px;
        }
        .thirdPartyMetisRight {
            margin-right: 20px;
        }
        .thirdPartyMetisCon {
            /* max-width: 227px; */

            box-sizing: border-box;
            height: 100px;
            width: 100%;
            padding: 19px 19px 19px 24px;
            background: #fff;
            border-radius: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 40px;
                height: fit-content;
                border-radius: 50%;
            }
            .thirdPartyInfo {
                display: flex;
                flex-flow: column nowrap;
                margin-left: 12px;
                flex-grow: 1;
                p,
                span {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.c.t01};
                    line-height: 20px;
                    text-align: left;
                }
                span {
                    font-weight: 400;

                    color: #07ddd0;
                }
            }

            .MuiSvgIcon-root {
                color: #9d9d9d !important;
            }
        }
    }
    .thirdPartySection {
        /* background-image: url('../../assets/images/bg-use.png'); */

        border-radius: 32px;
        background: ${({ theme }) => theme.c.bg05};
        background-repeat: no-repeat;
        padding: 24px;
        border-radius: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        img {
            width: 44px;
            height: 37px;
        }
        .thirdPartyCon {
            display: flex;
            flex-flow: column nowrap;
            margin-left: 20px;
            flex-grow: 1;
            h2,
            p {
                margin: 0;
                text-align: left;
            }
            h2 {
                font-size: 18px;
                font-weight: 500;
                color: ${({ theme }) => theme.c.t01};
                line-height: 25px;
            }
            p {
                font-size: 12px;
                font-weight: 400;
                color: #9d9d9d;
                line-height: 17px;
                margin-top: 8px;
                word-break: break-word;
            }
        }
        .MuiSvgIcon-root {
            color: #9d9d9d !important;
        }
    }
`;
const ModalContent = styled.div`
    width: 100%;
    max-width: 520px;
    a {
        text-decoration: none;
    }
    h1 {
        margin: 0 0 22px 0;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
    }
    .thirdModalSection {
        width: 400px;
        padding: 14px 21px 17px 23px;

        background: #f9f9f9;
        box-sizing: border-box;
        border-radius: 16px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        padding: 12px;
        `}
        .rightRow {
            width: fit-content;
            ${({ theme }) => theme.mediaWidth.upToSmall`
            align-items: flex-start;
            `}
            .label {
                background: rgba(52, 72, 234, 0.1);
                border-radius: 24px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3448ea;
                display: block;
                padding: 2px 8px;
                margin-right: 12px;
                font-size: 10px;
                flex-shrink: 0;
                ${({ theme }) => theme.mediaWidth.upToSmall`
                padding: 2px 4px;
                `}
            }
        }
        img {
            height: fit-content;
            width: 32px;
            border-radius: 50%;
            flex-shrink: 0;
        }
        .thirdModalCon {
            display: flex;
            flex-direction: column;
            h2,
            p {
                margin: 0;
            }
            h2 {
                font-size: 14px;
                font-weight: 600;
                color: #000;
                line-height: 22px;
                width: 250px;
                display: flex;
                justify-content: space-between;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #f4b74f;
                }
            }
            p {
                height: 20px;
                font-size: 10px;
                font-weight: 400;

                color: #9d9d9d;
                line-height: 20px;
                margin-top: 7px;
            }
        }
    }
`;

const ActWebBanner = styled.img`
    width: 100%;
    height: fit-content;
    cursor: pointer;
    border-radius: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `}
`;

const ActMobileBanner = styled.img`
    display: none;
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    `}
`;

interface Props {
    isDeposit: boolean;
    isMetis: boolean;
}

function thirdPartyList({ isDeposit, isMetis }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const activityShow = useActivityShow();

    const thirdPartyData = [
        {
            name: 'Poly Network',
            des: 'Metis(BSC)/CAKE/BNB/BUSD',
            minu: 'ETA 8-15 minutes',
            icon: require('assets/images/poly_logo.png'),
            link: 'https://bridge.poly.network/',
        },
        {
            name: 'Relay Chain',
            des: 'rAVAX/WONE/rFTM/RELAY/MATIC',
            minu: 'ETA 3-15 minutes',
            icon: require('assets/images/relay_logo.png'),
            link: 'https://app.relaychain.com/#/',
        },
        {
            name: 'Celer Network',
            des: 'Metis',
            minu: 'ETA 5-20 minutes',
            icon: require('assets/images/celer_dark_logo.png'),
            link: 'https://cbridge.celer.network/',
        },
        {
            name: 'BoringDAO',
            des: 'AAVE/CRV/m.USDT/m.USDC/WETH',
            minu: 'ETA 3-10 minutes',
            icon: 'https://raw.githubusercontent.com/Netswap/token-lists/master/images/oPortal.png',
            link: 'https://oportal.boringdao.com/native_bridge?target_chain_id=1088',
        },
        {
            name: 'Synapse Bridge',
            des: 'm.USDC',
            minu: 'ETA 2-5 minutes',
            icon: require('assets/images/synapse_logo.svg'),
            link: 'https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=1088',
        },
        {
            name: 'O3 Swap',
            des: 'METIS/m.USDC/m.USDT/WETH/O3',
            minu: 'ETA 5-20 minutes',
            icon: require('assets/images/o3swap.png'),
            link: 'https://v2.o3swap.com/bridge',
        },
    ];

    return (
        <Wrapper>
            {false && !isDeposit && isMetis && (
                <div className={'thirdPartyMetisSection'}>
                    <a
                        href="https://cbridge.celer.network/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div
                            className={cx(
                                'thirdPartyMetisCon',
                                'thirdPartyMetisRight',
                            )}
                        >
                            <img
                                src={require('../../assets/images/celer_dark_logo.png')}
                                alt="CelerIcon"
                            />
                            <div className={'thirdPartyInfo'}>
                                <p>Withdraw with Celer Network</p>
                                <span>ETA 5-20 minutes</span>
                            </div>
                            <ArrowForwardIosIcon
                                style={{ color: '#65dacf', fontSize: '16px' }}
                            />
                        </div>
                    </a>
                    <a
                        href="https://bridge.poly.network/ "
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className={'thirdPartyMetisCon'}>
                            <img
                                src={require('../../assets/images/poly_logo.png')}
                                alt="PolyIcon"
                            />
                            <div className={'thirdPartyInfo'}>
                                <p>Withdraw with PolyNetwork(BSC)</p>
                                <span>ETA 4-5 minutes</span>
                            </div>
                            <ArrowForwardIosIcon
                                style={{ color: '#65dacf', fontSize: '16px' }}
                            />
                        </div>
                    </a>
                </div>
            )}

            {activityShow ? (
                <>
                    <ActWebBanner
                        onClick={() => {
                            window.open('https://metis.io/ecosystem');
                        }}
                        src={require('assets/images/activity/web-footer-3x.png')}
                    />
                    <ActMobileBanner
                        onClick={() => {
                            window.open('https://metis.io/ecosystem');
                        }}
                        src={MobileFooterSVG}
                    />
                </>
            ) : (
                <div
                    className={'thirdPartySection'}
                    onClick={() => {
                        window.open('https://metis.io/bridge/#3rd-party');
                        // setIsOpen(!isOpen);
                    }}
                >
                    <img
                        src={require('../../assets/images/ic_use.png')}
                        alt="IcUseIcon"
                    />
                    <div className={'thirdPartyCon'}>
                        <h2>Use of third-party bridge</h2>
                        <p>
                            Metis/AAVE/CRV/CAKE/BNB/BUSD/rAVAX/WONE/rFTM/RELAY/MATIC/m.USDC
                        </p>
                    </div>
                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                </div>
            )}

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                // customContentClass={'modalContent'}
            >
                <ModalContent>
                    <TYPE.main
                        fontSize={16}
                        textAlign="center"
                        marginBottom="32px"
                    >
                        Choose a bridge
                    </TYPE.main>
                    {thirdPartyData.map((item, idx) => (
                        // eslint-disable-next-line no-unused-expressions
                        <a
                            href={item.link}
                            target="_blank"
                            key={idx}
                            rel="noreferrer"
                        >
                            <div className={'thirdModalSection'}>
                                <div className={'thirdModalCon'}>
                                    <h2>{item.name}</h2>
                                    <p>{item.des}</p>
                                </div>
                                <Row justify="flex-end" className="rightRow">
                                    {!isDeposit && (
                                        <span className="label">
                                            {item.minu}
                                        </span>
                                    )}
                                    <img src={item.icon} alt={item.name} />
                                </Row>
                            </div>
                        </a>
                    ))}
                </ModalContent>
            </Modal>
        </Wrapper>
    );
}

export default thirdPartyList;
