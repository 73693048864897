import ABI from './abi.json';
import { ChainId } from '../../constants';
import sepoliaDeployed from '../sepoliaDeployed.json';

export const Config = {
    [ChainId.mainnet]: {
        address: '0x3980c9ed79d2c191A89E02Fa3529C60eD6e9c04b',
        abi: ABI,
    },
    [ChainId.testnet]: {
        address: sepoliaDeployed.Proxy__OVM_L1StandardBridge,
        abi: ABI,
    },
};
