import React from 'react';
import cx from 'classnames';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const ModalWrapper = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    position: relative;
    min-width: 200px;
    max-width: 95vw;
    padding: 24px 26px;
    outline: none;
    background: ${({ theme }) => theme.c.bg05};
    box-sizing: border-box;

    border-radius: 32px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
    max-width: calc(100vw - 2rem);
    padding: 1rem;
    `}
`;
const CloseIconWrapper = styled.div`
    position: absolute;
    right: 32px;
    top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.c.bg04};

    ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 1rem;
    top: 1rem;
    `}
`;

const StyledCloseIcon = styled(CloseIcon)`
    color: #aaaaaf;
    cursor: pointer;
    width: 16px !important;
    height: 16px !important;
`;

export interface Props {
    isOpen?: boolean;
    onClose?(): void;
    children?: React.ReactNode;
    customContentClass?: string;
    hideClose?: boolean;
    disableBackgroundClick?: boolean;
    keepMounted?: boolean;
    className?: string;
}

export default function TransitionsModal({
    isOpen,
    onClose,
    children,
    customContentClass,
    hideClose,
    keepMounted,
    disableBackgroundClick = false,
    className,
}: Props) {
    return (
        <ModalWrapper
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={cx(className)}
            open={!!isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            keepMounted={keepMounted}
            // disableBackdropClick={disableBackgroundClick}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={isOpen}>
                <Content className={cx(customContentClass)}>
                    {!hideClose && (
                        <CloseIconWrapper>
                            <StyledCloseIcon onClick={onClose} />
                        </CloseIconWrapper>
                    )}
                    {children}
                </Content>
            </Zoom>
        </ModalWrapper>
    );
}
