import {
    EnvLayerToChainId,
    Layer,
    Token,
    TokenConfig,
    TokenList,
    TxActStatus,
} from '../../constants';
import {
    setToggleContractFlag,
    setTokenLoading,
    updateTokenContract,
    updateTokenDetail,
} from '.';
import { useAppDispatch, useAppSelector } from '../hooks';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import { getWeb3ByEnvLayer, getContractByEnvLayer } from 'hooks/use-contract';
import ERC20_ABI from 'contracts/erc20.json';
import { getPolisClient } from 'utils';

export function updateTokenBalanceHooks() {
    const { client, env } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const user = useAppSelector((state) => state.user);
    const { isPolis, address } = user;
    const dispatch = useAppDispatch();
    const { contract } = useAppSelector((state) => state.tokens);

    async function update(name: Token, layer: Layer) {
        if (TokenList.indexOf(name) < 0) return;
        const isLayer1Eth = layer === Layer.layer1 && name === Token.eth;
        const hasAddress = TokenConfig[name][layer][env].address;
        if (!isLayer1Eth && !hasAddress) return;
        dispatch(
            setTokenLoading({
                name,
                layer,
                loading: true,
            }),
        );

        const chainId = EnvLayerToChainId[env][layer];
        const tokenContract = contract[chainId]
            ? contract[chainId][name]
            : undefined;

        if (user.address) {
            try {
                let balance = new BigNumber(0);
                let decimals = 0;

                const domain = TokenConfig[name].domain;
                if (isLayer1Eth) {
                    decimals = 18;
                } else {
                    if (isPolis && client) {
                        const decimalsRes = await client.sendTxAsync(
                            domain,
                            EnvLayerToChainId[env][layer],
                            'decimals',
                            [],
                            true,
                        );
                        if (
                            decimalsRes &&
                            decimalsRes.act === TxActStatus.SUCCESS
                        ) {
                            const data = Number(decimalsRes.result);
                            if (typeof data === 'number') {
                                decimals = data;
                            }
                        }
                    } else if (tokenContract) {
                        const res = await tokenContract.methods
                            .decimals()
                            .call();
                        if (res) {
                            decimals = Number(res);
                        }
                    }
                }

                if (decimals) {
                    if (isLayer1Eth) {
                        if (isPolis) {
                            const polisClient = await getPolisClient(
                                EnvLayerToChainId[env][Layer.layer1],
                                true,
                            );
                            const ethBalanceRes =
                                await polisClient.web3Provider.getBalance(
                                    user.address,
                                );
                            if (ethBalanceRes) {
                                balance = new BigNumber(
                                    new BigNumber(ethBalanceRes.toString())
                                        .shiftedBy(-18)
                                        .toFixed(8, 1),
                                );
                            }
                        } else {
                            try {
                                const web3 = getWeb3ByEnvLayer(env, layer);
                                const res = await web3.eth.getBalance(
                                    user.address,
                                );
                                balance = new BigNumber(
                                    new BigNumber(res)
                                        .shiftedBy(-18)
                                        .toFixed(8, 1),
                                );
                            } catch (e) {
                                console.error(e);
                            }
                        }
                    } else {
                        if (isPolis && client) {
                            const balanceRes = await client.sendTxAsync(
                                domain,
                                EnvLayerToChainId[env][layer],
                                'balanceOf',
                                [user.address.toLowerCase()],
                                true,
                            );
                            if (
                                balanceRes &&
                                balanceRes.act === TxActStatus.SUCCESS
                            ) {
                                const data = Number(balanceRes.result);
                                if (typeof data === 'number') {
                                    balance = new BigNumber(
                                        new BigNumber(data)
                                            .shiftedBy(-decimals)
                                            .toFixed(8, 1),
                                    );
                                }
                            } else {
                                console.error(
                                    `Token ${name} , get balance error`,
                                );
                            }
                        } else {
                            try {
                                if (tokenContract) {
                                    const res = await tokenContract.methods
                                        .balanceOf(user.address)
                                        .call();
                                    if (res) {
                                        balance = new BigNumber(
                                            new BigNumber(res)
                                                .shiftedBy(-decimals)
                                                .toFixed(8, 1),
                                        );
                                    }
                                }
                            } catch (e) {
                                console.error(
                                    `Token ${name} , get balance error`,
                                );
                            }
                        }
                    }

                    dispatch(
                        updateTokenDetail({
                            name,
                            layer,
                            detail: {
                                decimals: decimals,
                                loading: false,
                                balance,
                            },
                        }),
                    );
                }
            } catch (e) {
                console.error(e);
                console.error(
                    `update balance error: ${name},${layer},${user.address} `,
                );
            }
        }
    }

    return update;
}
export default function TokensHook() {
    const { env, client, layer } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const user = useAppSelector((state) => state.user);
    const updateTokenBalance = updateTokenBalanceHooks();
    const { tokenList, contractInitFlag } = useAppSelector(
        (state) => state.tokens,
    );
    const dispatch = useAppDispatch();

    function updateAllTokenBalance() {
        for (let i = 0, len = tokenList.length; i < len; i++) {
            updateTokenBalance(tokenList[i], Layer.layer1);
            updateTokenBalance(tokenList[i], Layer.layer2);
        }
    }

    useEffect(() => {
        let interval: any;
        if (user.address) {
            updateAllTokenBalance();
            interval = setInterval(() => {
                updateAllTokenBalance();
            }, 30000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [env, client, user.address, tokenList, contractInitFlag]);

    function updateTokenContactByLayer(layer: Layer) {
        for (let i = 0, len = tokenList.length; i < len; i++) {
            const token = TokenList[i];
            const tokenAddress = TokenConfig[token][layer][env].address;
            if (tokenAddress) {
                const contract = getContractByEnvLayer(
                    tokenAddress,
                    ERC20_ABI,
                    env,
                    layer,
                );
                dispatch(
                    updateTokenContract({
                        chainId: EnvLayerToChainId[env][layer],
                        tokenName: token,
                        contract,
                    }),
                );
            }
        }
    }

    useEffect(() => {
        if (user.chainId && user.address && !user.isPolis && user.web3) {
            updateTokenContactByLayer(Layer.layer1);
            updateTokenContactByLayer(Layer.layer2);
            dispatch(setToggleContractFlag());
        }
    }, [user.address, user.isPolis, tokenList, env, user.web3]);

    return null;
}
