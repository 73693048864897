import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SelectLng from '../SelectLng';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import ConnectWalletButton from './connect-wallet';
import { useAppSelector } from 'state/hooks';
import SelectNetwork from 'components/SelectNetwork';
import styled from 'styled-components';
import { useToggleTheme } from 'hooks/use-toggle-theme';
import useActivityShow from 'hooks/useActivityShow';
import BanxaDisclaimerModal from './BanxaDisclaimerModal';
import IcWhiteSVG from 'assets/images/ic_white.svg';
import IcDarkSVG from 'assets/images/ic_dark.svg';
const HeaderWapper = styled.div`
    position: fixed;
    z-index: 88;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background: transparent;
    // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    background: ${({ theme }) => theme.c.bg01};

    &.showBg {
        background: #000;
        transition: all 0.3s linear;
    }

    &.showNoti {
        height: fit-content;
        padding: 0 !important;
    }

    .logoContainer {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        img {
            width: 44px;
            height: 44px;
        }

        .logoText {
            font-size: 18px;
            color: ${({ theme }) => theme.c.t01};
            font-weight: 800;
            margin-left: 20px;
        }
        .valentinesday {
            margin-left: 16px;
            max-width: 70px;
            max-height: 56px;
        }
    }
    .content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        &.showNoti {
            height: 80px;
        }
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0
        `}

        &.showNoti {
            ${({ theme }) => theme.mediaWidth.upToSmall`
            padding: 0 10px !important;
            `};
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        height: 100%;

        .right-bg {
            display: none;
        }

        .nav-container {
            flex: 1;
            height: 100%;
            .nav-list {
                display: flex;
                align-items: center;
                // margin: 0;
                height: 100%;
                list-style: none;
                margin: 0;

                .nav-item {
                    margin: 0 10px;

                    font-size: 18px;
                    color: #fff;
                    border-radius: 100px;
                    cursor: pointer;

                    &.profile {
                        display: none;
                    }

                    a {
                        display: inline-block;
                        padding: 10px 16px;
                        color: #fff;
                        text-decoration: none;
                        font-size: 18px;
                        font-weight: bold;

                        &:hover {
                            color: $pink;
                        }

                        &.active {
                            border-bottom: 1px solid $pink;
                        }
                    }
                }
            }
        }

        .connect-button {
            margin-bottom: -25px;
            padding: 10px 20px;
            background-color: #214652;
            font-size: 0.8rem;
            color: #fff;
            border: 0;
            border-radius: 100px;
            cursor: pointer;

            &.wrong {
                background-color: #ff6871;
            }
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;

        .content {
            width: 100%;

            .menu-icon {
                display: block;
                position: relative;
                width: 24px;
                height: 24px;
                font-size: 0;
                z-index: 2;

                &::before,
                &::after {
                    display: block;
                    position: absolute;
                    left: 2px;
                    content: '';
                    width: 20px;
                    height: 2px;
                    background: $pink;
                    transition: 0.3s;
                    cursor: pointer;
                }

                &::before {
                    top: 6.5px;
                }

                &::after {
                    top: 14.5px;
                }
            }

            .right {
                display: none;
                flex-direction: column;
                justify-content: center;
                position: fixed;
                z-index: -1;
                top: 0;
                right: 0;
                width: 80vw;
                height: 100vh;
                font-size: 0;
                opacity: 0;
                transition: 0.3s;
                border-left: 4px solid $pink;

                .right-bg {
                    display: block;
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: transparent;
                    z-index: -1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $lightDark;
                    opacity: 0.8;
                    z-index: -1;
                }

                .nav-container {
                    flex: initial;
                    width: 100%;
                    height: initial;

                    .nav-list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 0;
                        margin-top: -100px;
                        width: 100%;

                        .nav-item {
                            margin-bottom: 20px;
                            padding: 0;
                            width: 100%;
                            text-align: center;
                            font-size: 1.6rem;
                            background-color: transparent;

                            &.profile {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.open {
            .menu-icon {
                &::before,
                &::after {
                    top: 10px;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }

            .right {
                display: flex;
                opacity: 1;
                z-index: 1;
            }
        }
    `}
`;

const MobileWrapper = styled.div`
    display: none;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        position: fixed;
        z-index: 10;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.c.bg01};
        box-sizing: border-box;
        padding: 8px 4px;
  `}
`;

const ThemeButton = styled.div`
    width: 40px;
    height: 40px;
    margin-left: 20px;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.c.bg02};
    cursor: pointer;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    `}
`;

const ThemeImg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${({ theme }) =>
        theme.isDark ? IcWhiteSVG : IcDarkSVG});
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
`;

const ActWebbanner = styled.img`
    position: absolute;
    width: 400px;
    height: 56px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 20px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 400px;
    height: 56px;
    `}

    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `}
`;

const ActMobileBanner = styled.img`
    display: none;
    position: absolute;
    width: 164px;
    height: auto;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border-radius: 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    `}
`;

const Noti = styled.div`
    width: 100%;
    background: linear-gradient(270deg, #00cfff 0%, #0057fd 100%);
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 18px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    `};
`;

interface Props {
    showConnectButton?: boolean;
    connected?: boolean;
    onClickConnect?(): void;
    onClickDisconnect?(): void;
    address?: string;
    showLanguage?: boolean;
    notSupportedChain?: boolean;
}

export default function Header(props: Props) {
    const toggleTheme = useToggleTheme();

    const {
        // connected,
        // onClickConnect,
        showLanguage,
        // notSupportedChain,
    } = props;
    const [headerBgShow, setHeaderBgShow] = useState(false);
    const {
        connected,
        address: walletAddress,
        showNoti,
    } = useAppSelector((state) => state.user);
    const [isOpen, setIsOpen] = useState(false);
    const [isValentinesday, setIsValentinesday] = useState(false);
    const history = useHistory();
    const [isOpenBanxa, setIsOpenBanxa] = useState(false);

    async function disconnect() {
        window.location.reload();
    }

    useEffect(() => {
        const dom: Element | null = document.querySelector('.container');
        const scroll = () => {
            if (dom) {
                if (dom.scrollTop >= 40) {
                    setHeaderBgShow(true);
                } else {
                    setHeaderBgShow(false);
                }
            }
        };
        if (dom) {
            dom.addEventListener('scroll', scroll);
        }

        return () => {
            if (dom) {
                dom.removeEventListener('scroll', scroll);
            }
        };
    }, []);

    useEffect(() => {
        const now = new Date().getTime();
        if (1644750000000 <= now && now <= 1644922800000) {
            setIsValentinesday(true);
        }
    }, []);

    const activityShow = true;

    return (
        <>
            <HeaderWapper
                className={classNames(
                    'header',
                    isOpen && 'open',
                    headerBgShow && 'showBg',
                    showNoti && 'showNoti',
                )}
            >
                {showNoti && (
                    <Noti>
                        Metis is decentralizing its sequencer, making history as
                        the first Ethereum Layer 2 to do so.
                        <br />
                        Please be patient as the upgrade is currently taking
                        place.
                    </Noti>
                )}
                <div className={classNames(showNoti && 'showNoti', 'content')}>
                    {activityShow && (
                        <>
                            <ActWebbanner
                                onClick={() => {
                                    setIsOpenBanxa(true);
                                }}
                                src={require('assets/images/activity/banxa-pc.png')}
                            />
                            <ActMobileBanner
                                src={require('assets/images/activity/banxa-mobile.png')}
                                onClick={() => {
                                    setIsOpenBanxa(true);
                                }}
                            />
                        </>
                    )}

                    <div className="logoContainer">
                        <img
                            className="logo"
                            src="/logo.svg"
                            alt="Metis logo"
                            onClick={() => {
                                history.push('/home');
                            }}
                        />
                        <div className="logoText">Metis Bridge</div>
                        {isValentinesday && (
                            <img
                                className="valentinesday"
                                src={require('../../assets/images/valentinesday.gif')}
                                alt="valentinesday"
                            />
                        )}
                    </div>
                    {/* <div
                    className="menu-icon"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    Menu
                </div> */}
                    <div className="right" onClick={() => setIsOpen(false)}>
                        <SelectNetwork />
                        <ConnectWalletButton
                            disconnect={disconnect}
                            connected={connected}
                            walletAddress={walletAddress || ''}
                        />
                        <ThemeButton onClick={toggleTheme}>
                            <ThemeImg />
                        </ThemeButton>
                        {showLanguage && <SelectLng />}
                    </div>
                </div>
            </HeaderWapper>
            <MobileWrapper>
                <ConnectWalletButton
                    disconnect={disconnect}
                    connected={connected}
                    walletAddress={walletAddress || ''}
                />
                <SelectNetwork />
                <ThemeButton onClick={toggleTheme}>
                    <ThemeImg />
                </ThemeButton>
            </MobileWrapper>
            <BanxaDisclaimerModal
                isOpen={isOpenBanxa}
                onClose={() => {
                    setIsOpenBanxa(false);
                }}
            />
        </>
    );
}
