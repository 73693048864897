import React from 'react';
import Modal, { Props } from './modal';
import Row from 'components/Row';
import Button from 'components/Button';
import { Title, Info } from 'components/Text';
import SuccessImg from 'assets/images/success-modal-bg.png';
import ErrorImg from 'assets/images/error-modal-bg.png';
import SorryImg from 'assets/images/sorry-modal-bg.png';
import FailImg from 'assets/images/too-late.png';
import { Congratulation } from 'components/Lottie';
import styled from 'styled-components';
import { Provider } from 'react-redux';
import { store } from 'state';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider from 'theme';
const Wrapper = styled.div`
    width: 460px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;

    .imgContainer {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;

        .image {
            width: 133px;
            height: 118px;
        }

        .animation {
            position: absolute;
            top: -20%;
            left: 0;
            right: 0;
            bottom: 20%;
            z-index: -1;
        }
    }

    .footer {
        width: 100%;
        margin-top: 20px;
        padding-bottom: 20px;

        .button {
            width: 260px;
        }
    }
`;

interface CustomProps extends Props {
    type?: 'success' | 'error' | 'sorry' | 'fail' | 'congratulations' | 'info';
    title?: string;
    text?: string;
    buttonText?: string;
    textAlign?: 'center' | 'left' | 'right';
    hideClose?: boolean;
    disableBackgroundClick?: boolean;
    onConfirm?(): void;
    className?: string;
}

const ModalTitle = {
    success: 'Success!',
    error: 'Something went wrong!',
    sorry: 'Sorry',
    fail: 'Sorry',
    congratulations: 'Congratulations',
    info: 'Title',
};

const ButtonText = {
    success: 'Next',
    error: 'OK',
    sorry: 'OK',
    fail: 'OK',
    congratulations: 'Next',
    info: 'OK',
};

const ModalImg = {
    success: SuccessImg,
    error: ErrorImg,
    sorry: ErrorImg,
    fail: FailImg,
    congratulations: SuccessImg,
    info: null,
};

export default function CustomModal({
    isOpen,
    type = 'success',
    onClose,
    title,
    text,
    textAlign = 'center',
    buttonText,
    onConfirm,
    hideClose,
    disableBackgroundClick,
    className,
}: CustomProps) {
    title = title || ModalTitle[type];
    buttonText = buttonText || ButtonText[type];
    const bg = ModalImg[type];
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider>
                    <Modal
                        isOpen={isOpen}
                        onClose={onClose}
                        hideClose={hideClose}
                        className={className}
                        disableBackgroundClick={disableBackgroundClick}
                    >
                        <Wrapper>
                            <Row lineHeight="none" className="imgContainer">
                                {bg && (
                                    <img src={bg} className="image" alt="" />
                                )}
                                {type === 'congratulations' && (
                                    <Congratulation
                                        customClass="animation"
                                        width="100%"
                                        height="100%"
                                        loop={false}
                                    />
                                )}
                            </Row>
                            <Row justify="center">
                                <Title style={{ textAlign: 'center' }}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    />
                                </Title>
                            </Row>
                            {text && (
                                <Row justify="center">
                                    <Info style={{ textAlign: textAlign }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: text,
                                            }}
                                        />
                                    </Info>
                                </Row>
                            )}
                            <div className="footer">
                                <Row justify="center">
                                    <Button
                                        className="button"
                                        onClick={() => {
                                            onConfirm && onConfirm();
                                            onClose && onClose();
                                        }}
                                        size="middle"
                                    >
                                        {buttonText}
                                    </Button>
                                </Row>
                            </div>
                        </Wrapper>
                    </Modal>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}
