import { useAppSelector } from 'state/hooks';
import { setIsOpenConnectModalHooks } from 'state/user/hooks';

export default function useConnectWallet() {
    const { address, connected } = useAppSelector((state) => state.user);
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();

    function connectWallet() {
        setIsOpenConnectModal(true);
    }

    return {
        connectWallet,
        walletAddress: address,
        connected,
    };
}
