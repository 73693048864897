import { Layer, Token } from '../constants';
import { useAppSelector } from 'state/hooks';
import BigNumber from 'bignumber.js';

function decodeErrorString(error: any) {
    let errorMessage = '';
    error = new Error(error).message;
    if (error) {
        const reg = new RegExp('Error: Internal JSON-RPC error.');
        if (reg.test(error)) {
            const message = error.replace(reg, '');

            const errorData = JSON.parse(message);

            if (
                errorData &&
                typeof errorData === 'object' &&
                errorData.message
            ) {
                errorMessage = errorData.message;
            }
        } else {
            errorMessage = error;
        }
    }
    return errorMessage;
}

export default function useGasEstimateForMaincoin() {
    const user = useAppSelector((state) => state.user);
    const { layer } = useAppSelector((state) => state.metisMiddlewareClient);
    const { tokens } = useAppSelector((state) => state.tokens);

    function gasEstimate(
        promise: any,
        maincoinUsed: BigNumber = new BigNumber(0),
    ) {
        return new Promise(async (resolve, reject) => {
            let gasPrice = 1000000000;
            if (gasPrice) {
                gasPrice = await user.web3.eth.getGasPrice();
            }
            if (promise) {
                promise.estimateGas(
                    {
                        from: user.address,
                    },
                    function (error: any, gasAmount: any) {
                        if (error) {
                            const errorMessage = decodeErrorString(error);
                            return reject(new Error(errorMessage));
                        } else {
                            const gas = new BigNumber(gasAmount)
                                .multipliedBy(gasPrice)
                                .shiftedBy(-18);
                            console.log('estimate trans fee: ', gas.toFixed());

                            const maincoinBalance =
                                layer === Layer.layer1
                                    ? tokens[Token.eth][layer].balance
                                    : tokens[Token.metis][layer].balance;

                            if (
                                maincoinUsed
                                    .plus(gas)
                                    .isGreaterThan(maincoinBalance)
                            ) {
                                const symbol =
                                    layer === Layer.layer1 ? 'ETH' : 'METIS';
                                return reject(
                                    new Error(
                                        `The current ${symbol} balance ${user.metisBalance.toFixed(
                                            3,
                                        )} on your account is not sufficient to support this execution. Please leave at least ${gas.toFixed(
                                            8,
                                            1,
                                        )} ${symbol} to support this execution`,
                                    ),
                                );
                            } else {
                                resolve(true);
                            }
                        }
                    },
                );
            }

            // return resolve(false);
        });
    }

    return gasEstimate;
}
