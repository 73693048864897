/* eslint-disable no-script-url */
import React, { useMemo, useState } from 'react';
import Text from '../Text';
import { formatHashValue } from 'utils';

import ConnectWalletButton from 'pages/components/ConnectWalletButton';
import AccountDetail from 'components/AccountDetail';
import styled from 'styled-components';
import makeBlockie from 'ethereum-blockies-base64';
import { Layer, Token, TokenConfig } from '../../constants';
import { useAppSelector } from 'state/hooks';
import { TYPE } from 'theme';

const AccountButton = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    padding: 0 0 0 10px !important;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.c.bg02};
    color: #9393aa;

    border-radius: 15px;
    overflow: hidden;
`;

const Address = styled.div`
    background: #0a0a15;
    height: 100%;
    display: flex;
    align-content: center;
    color: #fff;
    padding: 10px;

    border-radius: 15px;
    margin-left: 10px;

    img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        border-radius: 12px;
    }
`;

interface Props {
    disconnect(): void;
    connected: boolean;
    walletAddress: string;
}

export default function ConnectButton({
    disconnect,
    connected,
    walletAddress,
}: Props) {
    const { layer } = useAppSelector((state) => state.metisMiddlewareClient);
    const mainnetToken = layer === Layer.layer1 ? Token.eth : Token.metis;
    const token = TokenConfig[mainnetToken];
    const { tokens } = useAppSelector((state) => state.tokens);

    const tokenDetail = useMemo(() => {
        if (tokens[mainnetToken]) {
            return tokens[mainnetToken][layer];
        }
        return undefined;
    }, [layer, tokens[mainnetToken]]);

    const [isOpenAccountDetail, setIsOpenAccountDetail] = useState(false);

    return (
        <>
            {!connected || !walletAddress ? (
                <ConnectWalletButton />
            ) : (
                <>
                    <AccountButton
                        onClick={() => {
                            setIsOpenAccountDetail(true);
                        }}
                    >
                        <TYPE.main fontSize={12}>
                            {tokenDetail
                                ? `${tokenDetail.balance.toFixed(3)} ${
                                      token[layer].symbol
                                  }`
                                : '--'}
                        </TYPE.main>
                        <Address>
                            <TYPE.main
                                color={'#fff'}
                                fontSize={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {formatHashValue(walletAddress)}
                            </TYPE.main>
                            <img src={makeBlockie(walletAddress)} alt="" />
                        </Address>
                        {/* <ArrowDropDownIcon className={style.hideDropdownIcon} /> */}
                    </AccountButton>
                    {/* <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        className={style.menuWrapper}
                        open={Boolean(anchorEl)}
                        getContentAnchorEl={null}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        TransitionComponent={Fade}
                    >
                        <MenuItem className={style.menu} onClick={handleClose}>
                            <NavLink to="/profile">Profile</NavLink>
                        </MenuItem>
                        <MenuItem className={style.menu} onClick={handleClose}>
                            <a href="javascript:;" onClick={disconnect}>
                                Disconnect
                            </a>
                        </MenuItem>
                    </Menu> */}
                </>
            )}
            <AccountDetail
                isOpen={isOpenAccountDetail}
                onClose={() => setIsOpenAccountDetail(false)}
            />
        </>
    );
}
