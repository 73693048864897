import { ChainId } from '../../constants';
import { USER_INFO } from '../../constants/storage';
import storage from 'utils/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
// import type { RootState } from '../index';

// Define a type for the slice state

interface UserState {
    name?: string;
    email?: string;
    address?: string;
    connectError: string;
    connected: boolean;
    metisBalance: BigNumber;
    isOpenConnectModal: boolean;
    loadingUserInfo: boolean;
    web3ModalInstance?: any;
    web3Provider: any;
    web3: any;
    chainId?: ChainId;
    isPolis: boolean;
    isDark: boolean;
    showNoti: boolean;
}

const userInfo = storage.get(USER_INFO);

// Define the initial state using that type
const initialState: UserState = {
    name: userInfo && userInfo.display_name ? userInfo.display_name : '',
    email: userInfo && userInfo.email ? userInfo.email : '',
    address: userInfo && userInfo.address ? userInfo.address : '',
    connectError: '',
    connected: !!userInfo,
    metisBalance: new BigNumber(0),
    // role: userInfo && userInfo.role ? userInfo.role : ROLE.GENERAL_USER,
    isOpenConnectModal: false,
    loadingUserInfo: false,
    web3ModalInstance: undefined,
    web3Provider: undefined,
    web3: undefined,
    chainId: undefined,
    isPolis: false,
    isDark: storage.get('bridge-theme'),
    showNoti: false,
};

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setConnectError: (state, action: PayloadAction<string>) => {
            state.connectError = action.payload;
        },
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload;
        },
        setIsPolis: (state, action: PayloadAction<boolean>) => {
            state.isPolis = action.payload;
        },
        setMetisBalance: (state, action: PayloadAction<BigNumber>) => {
            state.metisBalance = action.payload;
        },
        setIsOpenConnectModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenConnectModal = action.payload;
        },
        setLoadingUserInfo: (state, action: PayloadAction<boolean>) => {
            state.loadingUserInfo = action.payload;
        },
        setWeb3ModalInstance: (state, action: PayloadAction<any>) => {
            state.web3ModalInstance = action.payload;
        },
        setWeb3Provider: (state, action: PayloadAction<any>) => {
            state.web3Provider = action.payload;
        },
        setWeb3: (state, action: PayloadAction<any>) => {
            state.web3 = action.payload;
        },
        setChainId: (state, action: PayloadAction<number | undefined>) => {
            state.chainId = action.payload;
        },
        setIsDark: (state, action: PayloadAction<boolean>) => {
            storage.set('bridge-theme', action.payload);
            state.isDark = action.payload;
        },
    },
});

export const {
    setAddress,
    setConnectError,
    setConnected,
    setEmail,
    setMetisBalance,
    setName,
    setIsOpenConnectModal,
    setLoadingUserInfo,
    setWeb3ModalInstance,
    setWeb3,
    setWeb3Provider,
    setChainId,
    setIsPolis,
    setIsDark,
} = userSlice.actions;

export default userSlice.reducer;
