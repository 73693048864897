// eslint-disable-next-line no-use-before-define
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import client from './graphql';
import { Provider } from 'react-redux';
import { store } from './state';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider, { ThemedGlobalStyle } from 'theme';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from 'common-helpers/walletclientConfig';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <Suspense fallback={<></>}>
        <ApolloProvider client={client}>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <RainbowKitProvider>
                        <Provider store={store}>
                            <StyledEngineProvider injectFirst>
                                <BrowserRouter>
                                    <ThemeProvider>
                                        <ThemedGlobalStyle />
                                        <App />
                                    </ThemeProvider>
                                </BrowserRouter>
                            </StyledEngineProvider>
                        </Provider>
                    </RainbowKitProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </ApolloProvider>
    </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
