import ABI from './abi.json';
import { ChainId } from '../../constants';
import sepoliaDeployed from '../sepoliaDeployed.json';
export const Config = {
    [ChainId.mainnet]: {
        address: '0xC8953ca384b4AdC8B1b11B030Afe2F05471664b0',
        abi: ABI,
    },
    [ChainId.testnet]: {
        address: sepoliaDeployed.MVM_DiscountOracle,
        abi: ABI,
    },
};
