import React, { useMemo, useState } from 'react';
import { ListItem } from '@mui/material';

import { Layer, Token, TokenConfig } from '../../constants';
import { useAppSelector } from 'state/hooks';
import Loading from '../Loading';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
    height: 64px;
    padding: 12px;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.c.bg04};

    img {
        width: 100%;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
    }

    .tokenItemCon {
        display: flex;
        flex-flow: column wrap;
        width: 90%;
        margin: 0 16px;
    }
    .tokenItemTitle {
        color: ${({ theme }) => theme.c.t01};
        font-weight: 600;
        font-size: 14px;
    }
    .tokenItemDes {
        color: ${({ theme }) => theme.c.t02};
        font-weight: 12px;
    }

    .tokenItemCount {
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        margin-right: 10px;
        color: ${({ theme }) => theme.c.t01};
    }

    &.tokenDisableItem {
        opacity: 1;
        cursor: not-allowed !important;
    }
`;

interface Props {
    name: Token;
    layer: Layer;
    onClick(name: Token): void;
}

export default function TokenInfo({ name, layer, onClick }: Props) {
    const token = TokenConfig[name];
    const { tokens } = useAppSelector((state) => state.tokens);

    const tokenDetail = useMemo(() => {
        if (tokens[name]) {
            return tokens[name][layer];
        }
        return undefined;
    }, [layer, tokens[name]]);

    const disabled = useMemo(() => {
        return !tokenDetail || !tokenDetail.balance.toNumber();
    }, [tokenDetail]);

    if (!tokenDetail) return null;

    return (
        <>
            <StyledListItem
                onClick={() => {
                    if (disabled) return;
                    onClick(name);
                }}
                className={disabled ? 'tokenDisableItem' : ''}
            >
                <img src={token.img} />
                <div className="tokenItemCon">
                    <div className="tokenItemTitle">{token[layer].symbol}</div>
                    <div className="tokenItemDes">{token[layer].name}</div>
                </div>
                <div className="tokenItemCount">
                    {!tokenDetail.loadingOnce &&
                    tokenDetail.loading &&
                    tokenDetail.balance.eq(0) ? (
                        <Loading style={{ color: '#fff' }} />
                    ) : (
                        tokenDetail.balance.toFixed(5, 1)
                    )}
                </div>
            </StyledListItem>
        </>
    );
}
