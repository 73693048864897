import {
    CONTRACT_DOMAIN,
    DOMAIN_CONFIG,
    EnvLayerToChainId,
    Layer,
    Token,
    TokenConfig,
    TxActStatus,
    TxStatus,
} from '../constants';
import { useAppSelector } from 'state/hooks';
import { handlePolisError } from 'utils';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useGetContractByUserWeb3 } from './use-contract';
import ERC20_ABI from 'contracts/erc20.json';
import { getBridgeSpenderByChainId } from 'contracts/utils';
import useGasEstimateForMaincoin from './use-estimate-gas-with-maincoin';

export default function useTokenApprove(
    token: Token,
    layer: Layer,
    tokenAmount: BigNumber,
) {
    const { client, env } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const user = useAppSelector((state) => state.user);
    const { tokens } = useAppSelector((state) => state.tokens);
    const getContract = useGetContractByUserWeb3();
    const gasEstimate = useGasEstimateForMaincoin();
    const isLayer1Eth = useMemo(() => {
        return layer === Layer.layer1 && token === Token.eth;
    }, [layer, token]);

    const tokenDetail = useMemo(() => {
        return tokens[token] ? tokens[token][layer] : undefined;
    }, [token, layer, tokens]);
    const [approved, setApproved] = useState(false);

    async function getIsNeedToApprove() {
        if (isLayer1Eth) return true;
        if (!tokenDetail) return;
        try {
            let tokenAllowance = new BigNumber(0);
            let spender =
                user.chainId && !user.isPolis
                    ? getBridgeSpenderByChainId(user.chainId).address
                    : '';
            let tokenContract: any;
            if (user.isPolis) {
                if (client) {
                    let spenderDomain;
                    if (layer === Layer.layer1) {
                        spenderDomain =
                            DOMAIN_CONFIG[env][Layer.layer1][
                                CONTRACT_DOMAIN.layer1Deposit
                            ];
                    } else {
                        spenderDomain =
                            DOMAIN_CONFIG[env][Layer.layer2][
                                CONTRACT_DOMAIN.layer2Withdraw
                            ];
                    }
                    const domainRes = await client.getDomain(
                        spenderDomain.domain,
                        `${EnvLayerToChainId[env][layer]}`,
                    );

                    if (domainRes && domainRes.contract_address) {
                        spender = domainRes.contract_address;
                        const allowanceRes = await client.sendTxAsync(
                            TokenConfig[token].domain,
                            EnvLayerToChainId[env][layer],
                            'allowance',
                            [user.address, spender],
                            true,
                        );
                        if (
                            allowanceRes &&
                            allowanceRes.act === TxActStatus.SUCCESS &&
                            tokenDetail
                        ) {
                            if (allowanceRes.result) {
                                tokenAllowance = new BigNumber(
                                    allowanceRes.result,
                                ).shiftedBy(-tokenDetail.decimals);
                            }
                        }
                    }
                }
            } else {
                tokenContract = getContract(
                    TokenConfig[token][layer][env].address,
                    ERC20_ABI,
                );

                const allowanceRes = await tokenContract.methods
                    .allowance(user.address, spender)
                    .call();

                if (allowanceRes && tokenDetail) {
                    tokenAllowance = new BigNumber(allowanceRes).shiftedBy(
                        -tokenDetail.decimals,
                    );
                    console.log(
                        `Token: ${token}, Allowance: ${tokenAllowance.toFixed()} (without decimals)`,
                    );
                } else {
                    console.error('get allowance error');
                }
            }

            const isNeedToApprove = tokenAllowance.isLessThan(tokenAmount);
            setApproved(!isNeedToApprove);
        } catch (e) {
            // handlePolisError(e);
            console.error(e);
            console.error('getIsNeedToApprove fail');
        }
        return false;
    }

    async function approveUserTokenForSpender() {
        if (isLayer1Eth) return true;
        try {
            const chainId = EnvLayerToChainId[env][layer];
            const spender = getBridgeSpenderByChainId(chainId).address;

            let tokenContract: any;

            if (!approved) {
                if (user.isPolis) {
                    if (client) {
                        const balanceRes = await client.sendTxAsync(
                            TokenConfig[token].domain,
                            EnvLayerToChainId[env][layer],
                            'balanceOf',
                            [user.address],
                            true,
                        );

                        const res = await client.sendTxAsync(
                            TokenConfig[token].domain,
                            EnvLayerToChainId[env][layer],
                            'approve',
                            [
                                spender,
                                balanceRes?.result ||
                                    ethers.constants.MaxUint256.toString(),
                            ],
                        );

                        if (
                            res &&
                            res.act === TxActStatus.SUCCESS &&
                            res.status === TxStatus.SUCCESS
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    tokenContract = getContract(
                        TokenConfig[token][layer][env].address,
                        ERC20_ABI,
                    );

                    const balanceRes = await tokenContract.methods
                        .balanceOf(user.address)
                        .call();

                    const promise = tokenContract.methods.approve(
                        spender,
                        balanceRes
                            ? new BigNumber(balanceRes).toFixed()
                            : ethers.constants.MaxUint256.toString(),
                    );

                    const success = await gasEstimate(promise);

                    if (!success) {
                        return false;
                    }

                    const res = await new Promise((resolve) => {
                        promise
                            .send({
                                from: user.address,
                            })
                            .on('receipt', function (receipt: any) {
                                resolve(receipt.transactionHash);
                            })
                            .on('error', function (e: any) {
                                resolve(false);
                                console.error(e);
                            });
                    });

                    if (res) {
                        await getIsNeedToApprove();
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                return true;
            }
        } catch (e) {
            handlePolisError(e);
            console.error(e);
            console.error('approveMetisTokenTo fail');
        }
        return false;
    }

    // useEffect(() => {
    //     if (user.address && client) {
    //         getSpenderDomain();
    //     }
    // }, [env, layer, client, user.address]);

    // useEffect(() => {
    //     if (client && user.address && spender && tokenDetail.decimals) {
    //         getIsNeedToApprove(layer, spender);
    //     }
    // }, [
    //     env,
    //     layer,
    //     token,
    //     client,
    //     user.address,
    //     spender,
    //     tokenDetail.decimals,
    // ]);
    useEffect(() => {
        if (user && user.address) {
            getIsNeedToApprove();
        }
    }, [layer, tokenAmount, tokenDetail, user]);

    return {
        approveUserTokenForSpender,
        approved,
    };
}
