import ABI from './abi.json';
import { ChainId } from '../../constants';

export const Config = {
    [ChainId.layer2Mainnet]: {
        address: '0x4200000000000000000000000000000000000010',
        abi: ABI,
    },
    [ChainId.layer2Testnet]: {
        address: '0x4200000000000000000000000000000000000010',
        abi: ABI,
    },
};
