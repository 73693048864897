import { ChainId } from '../constants';
import { Config as DepositConfig } from 'contracts/l1bridge';
import { Config as WithdrawConfig } from 'contracts/l2bridge-v2';

export function getBridgeSpenderByChainId(chainId: ChainId) {
    const config = {
        ...DepositConfig,
        ...WithdrawConfig,
    };

    return config[chainId];
}
