import { Layer } from '../constants';
import { useAppSelector } from 'state/hooks';
import { useState, useEffect, useMemo } from 'react';
// utc 11-20 00:00:00
const EndTime = 1668902400000;

const now = new Date().getTime();

export default function useActivityShow() {
    const { layer } = useAppSelector((state) => state.metisMiddlewareClient);

    const [timeEnd, setTimeEnd] = useState(now > EndTime);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();

            if (now > EndTime) {
                setTimeEnd(true);
            }
        }, 30000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [layer]);

    return useMemo(() => {
        return layer === Layer.layer1 && !timeEnd;
    }, [layer, timeEnd]);
}
