import React from 'react';
import Button from 'components/Button';
import useConnectWallet from 'hooks/use-connect-wallet';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    /* width: 130px; */
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    /* padding: 15px 30px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px !important;

    box-shadow: none !important;
`;

interface Props {
    className?: string;
}

export default function ConnectWallet({ className = '' }: Props) {
    const { connectWallet, connected } = useConnectWallet();

    if (connected) return null;

    return (
        <>
            <StyledButton
                onClick={() => {
                    connectWallet();
                }}
                className={className}
            >
                Connect Wallet
            </StyledButton>
        </>
    );
}
