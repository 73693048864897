import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpClient } from '@metis.io/middleware-client';
import { Env, Layer } from '../../constants';
// import type { RootState } from '../index';

// Define a type for the slice state
interface State {
    client?: HttpClient;
    env: Env;
    layer: Layer;
}

const isMainnet = Number(process.env.REACT_APP_IS_MAINNET) === 1;

// Define the initial state using that type
const initialState: State = {
    client: undefined,
    env: isMainnet ? Env.mainnet : Env.test,
    layer: Layer.layer1,
};

export const metisMiddlewareClient = createSlice({
    name: 'metisMiddlewareClient',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setClient: (state, action: PayloadAction<HttpClient | undefined>) => {
            state.client = action.payload;
        },
        setEnv: (state, action: PayloadAction<Env>) => {
            state.env = action.payload;
        },
        setLayer: (state, action: PayloadAction<Layer>) => {
            state.layer = action.payload;
        },
    },
});

export const { setClient, setEnv, setLayer } = metisMiddlewareClient.actions;

export default metisMiddlewareClient.reducer;
