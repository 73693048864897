import React from 'react';
import styled from 'styled-components';
import Row from '../Row';
import { Info } from '../Text';
// import Modal from 'utils/components/modal';
//winddy 21/11/19 modify html and style for https://metisdao.atlassian.net/browse/BRIDGE-22

const FooterWrapper = styled.div`
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
    opacity: 0.5;
    font-size: 16px;
    .link {
        display: flex;
        flex-wrap: wrap;
        line-height: 1.6;
        margin-bottom: 8px;
        a,
        span {
            text-decoration: none;
            color: ${({ theme }) => theme.c.t01};
            cursor: pointer;
            flex-shrink: 0;
            line-height: 22px;
        }
    }
    .copyright {
        text-align: center;
        color: ${({ theme }) => theme.c.t01};
    }
`;
export default function Footer() {
    return (
        <FooterWrapper>
            <Row justify="center" lineHeight="none" className={'link'}>
                {/* <Info align="center">
                    Disclaimer: Under current applicable regulations, access to
                    this promotion is not available for residents of, or
                    persons/entities located in, the United States of America or
                    Canada. We are proactively working towards tailoring future
                    promotions to become available worldwide"
                </Info> */}
                <a
                    href="/terms-of-service.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms and Service
                </a>
                <a
                    style={{ marginLeft: '33px' }}
                    href="/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Policy
                </a>
            </Row>
            <div className="copyright">
                © Copyright 2021 by MetisDAO Foundation Ltd.
            </div>
        </FooterWrapper>
    );
}
