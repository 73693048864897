import React from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/Pagination';
import TableRow from '@mui/material/TableRow';
import { ArrowLeft } from 'react-feather';
import moment from 'moment';
import { formatHashValue } from 'utils';
import {
    Env,
    Layer,
    NETWORK_CONFIG,
    Token,
    TokenConfig,
} from '../../constants';
import useGetBridgeHistoryRecords, {
    RecordStatus,
} from 'hooks/use-get-bridge-history-records';
import Loading from 'components/Loading';
import { useAppSelector } from 'state/hooks';
import { Info } from 'components/Text';
import Row, { RowBetween } from 'components/Row';
import styled from 'styled-components';
import { TYPE } from 'theme';

const HistoryWrapper = styled.div`
    display: block;
    width: 560px;
    background: ${({ theme }) => theme.c.bg05};
    margin: 0 auto;
    // margin-top: 30px;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px 30px;
    padding-top: 0;
    padding: 24px;
    width: 100%;
    max-width: 1088px;

    .historyPaper {
        margin-top: 20px;
        background: ${({ theme }) => theme.c.bg04};

        .MuiTableCell-head {
            font-size: 12px;
            font-weight: 400;
            color: #a2a2a8;
            line-height: 16px;
            border-bottom: none;
            padding: 16px 0;
        }
        .MuiTableCell-body {
            font-size: 12px;
            font-weight: 400;
            color: ${({ theme }) => theme.c.t01};
            line-height: 16px;
            border: none;
        }
        .MuiPagination-root {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .MuiTablePagination-root {
            color: ${({ theme }) => theme.c.t01};
            font-size: 14px;
            display: flex;
            justify-content: center;
            margin-top: 10px;

            .MuiTablePagination-actions {
                margin-left: 0px;
            }

            .MuiIconButton-root.Mui-disabled {
                display: none;
            }
        }
        .MuiTablePagination-selectRoot {
            display: none;
        }
    }
    .historyContainer {
        width: 100%;
        overflow-x: unset;
        .addressLink {
            font-size: 12px;
            font-weight: 400;
            color: ${({ theme }) => theme.c.t01};
            line-height: 16px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
        .tokenInfo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                max-width: 24px;
                margin-right: 8px;
            }
        }
    }
`;

interface Column {
    id:
        | 'txid'
        | 'source'
        | 'destination'
        | 'amount'
        | 'time'
        | 'eta'
        | 'status';
    label: string;
    minWidth?: number;
}

const columns: Column[] = [
    { id: 'txid', label: 'TX ID', minWidth: 110 },
    { id: 'source', label: 'Source Chain', minWidth: 130 },
    { id: 'destination', label: 'Destination Chain', minWidth: 130 },
    { id: 'amount', label: 'Amount', minWidth: 100 },
    { id: 'time', label: 'Time', minWidth: 220 },
    { id: 'eta', label: 'ETA / Arrival Time', minWidth: 220 },
    { id: 'status', label: 'Status', minWidth: 100 },
];

function getTxLink(env: Env, isDeposit: boolean, tx: string) {
    const explore =
        NETWORK_CONFIG[env][isDeposit ? Layer.layer1 : Layer.layer2].explore;
    return `${explore}/tx/${tx}`;
}

export default function History() {
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    // const [rowsPerPage, setRowsPerPage] = React.useState(2);
    const rowsPerPage = 6;
    const tokenMetis = TokenConfig[Token.metis];
    const tokenETH = TokenConfig[Token.eth];
    const { env } = useAppSelector((state) => state.metisMiddlewareClient);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const { records, loading } = useGetBridgeHistoryRecords();

    // const handleChangeRowsPerPage = (
    //     event: React.ChangeEvent<HTMLInputElement>,
    // ) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    return (
        <>
            <HistoryWrapper>
                <RowBetween lineHeight="none">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            history.push('/home');
                        }}
                    >
                        <ArrowLeft color="#9D9D9D" />
                        <TYPE.desc marginLeft={'10px'}>Back</TYPE.desc>
                    </div>
                    <TYPE.main fontSize={32}>Transaction History</TYPE.main>
                    <span style={{ opacity: 0 }}></span>
                </RowBetween>
                <Paper className={'historyPaper'}>
                    <TableContainer className={'historyContainer'}>
                        <Table aria-label="history table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                            style={{ width: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records
                                    .slice(
                                        (page - 1) * rowsPerPage,
                                        (page - 1) * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`record-${index}`}
                                            >
                                                <TableCell align="center">
                                                    <a
                                                        className={
                                                            'addressLink'
                                                        }
                                                        href={getTxLink(
                                                            env,
                                                            row.isDeposit,
                                                            row.id,
                                                        )}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {formatHashValue(
                                                            row.id,
                                                        )}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        className={'tokenInfo'}
                                                    >
                                                        {!row.isDeposit ? (
                                                            <>
                                                                <img
                                                                    src={
                                                                        tokenMetis.img
                                                                    }
                                                                    alt="tokenMetis"
                                                                />
                                                                {
                                                                    tokenMetis[
                                                                        Layer
                                                                            .layer1
                                                                    ].symbol
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    src={
                                                                        tokenETH.img
                                                                    }
                                                                    alt="tokenETH"
                                                                />
                                                                {
                                                                    tokenETH[
                                                                        Layer
                                                                            .layer1
                                                                    ].symbol
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        className={'tokenInfo'}
                                                    >
                                                        {row.isDeposit ? (
                                                            <>
                                                                <img
                                                                    src={
                                                                        tokenMetis.img
                                                                    }
                                                                    alt="tokenMetis"
                                                                />
                                                                {
                                                                    tokenMetis[
                                                                        Layer
                                                                            .layer1
                                                                    ].symbol
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    src={
                                                                        tokenETH.img
                                                                    }
                                                                    alt="tokenETH"
                                                                />
                                                                {
                                                                    tokenETH[
                                                                        Layer
                                                                            .layer1
                                                                    ].symbol
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.amount} {row.symbol}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {moment(
                                                        row.timestamp,
                                                    ).format(
                                                        'MMM DD, YYYY HH:mm:ss (UTC Z)',
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.status ===
                                                        RecordStatus.finished &&
                                                    row.finishTimestamp ? (
                                                        moment(
                                                            row.finishTimestamp,
                                                        ).format(
                                                            'MMM DD, YYYY HH:mm:ss (UTC Z)',
                                                        )
                                                    ) : row.isDeposit ? (
                                                        '-'
                                                    ) : row.status === 0 ? (
                                                        <span
                                                            style={{
                                                                letterSpacing:
                                                                    '-1px',
                                                            }}
                                                        >
                                                            Estimated time of
                                                            arrival is before
                                                            <br />
                                                            {moment
                                                                .utc(
                                                                    row.timestamp,
                                                                )
                                                                .add(8, 'days')
                                                                .format(
                                                                    'MMM DD, YYYY',
                                                                )}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {moment(
                                                                row.timestamp,
                                                            )
                                                                .add(8, 'days')
                                                                .format(
                                                                    'MMM DD, YYYY HH:mm:ss (UTC Z)',
                                                                )}
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.status === 0 ? (
                                                        <span
                                                            style={{
                                                                color: '#65dacf',
                                                            }}
                                                        >
                                                            Pending
                                                        </span>
                                                    ) : (
                                                        'Finish'
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {!loading && !records.length && (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Row align="center">
                                                <Info>No records</Info>
                                            </Row>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loading ? (
                        <Loading
                            style={{ display: 'block', margin: '0 auto' }}
                        />
                    ) : records.length ? (
                        <TablePagination
                            // rowsPerPageOptions={[10, 25, 100]}
                            count={Math.floor(
                                records.length > rowsPerPage
                                    ? records.length / rowsPerPage + 1
                                    : 1,
                            )}
                            page={page}
                            onChange={handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    ) : null}
                    <Row
                        justify="center"
                        style={{ width: '100%', padding: '12px' }}
                    >
                        <TYPE.desc textAlign="center">
                            Only the lastest 100 deposit/withdrawal records are
                            showing here.
                        </TYPE.desc>
                    </Row>
                </Paper>
            </HistoryWrapper>
        </>
    );
}
