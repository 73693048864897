import React, { useMemo } from 'react';
import styled, {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
    css,
    DefaultTheme,
} from 'styled-components';
import { Text, TextProps } from 'rebass';
import { darken } from 'polished';
import { useIsDarkMode } from 'state/user/hooks';
const MEDIA_WIDTHS = {
    upToExtraSmall: 500,
    upToSmall: 720,
    upToMedium: 960,
    upToLarge: 1200,
    upToMoreLarge: 1440,
};

const mediaWidthTemplates: {
    [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
    (accumulator as any)[size] = (a: any, b: any, c: any) => css`
        @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
            ${css(a, b, c)}
        }
    `;
    return accumulator;
}, {}) as any;

export function color(isDark: boolean) {
    return {
        bg01: isDark ? '#10101C' : '#fff',
        bg02: isDark ? '#313144' : '#f1f4f9',
        bg03: isDark ? '#10101C' : '#fff',
        bg04: isDark ? '#131324' : '#f9f9f9',
        bg05: isDark ? '#1C1C33' : '#fff',
        t01: isDark ? '#fff' : '#000',
        t02: '#9d9d9d',
        t03: '#00cfff',
        t04: isDark ? '#00cfff' : '#000',
    };
}

export function theme(darkMode: boolean): DefaultTheme {
    return {
        isDark: darkMode,
        mediaWidth: mediaWidthTemplates,
        c: color(darkMode),
    };
}

export const ThemedGlobalStyle = createGlobalStyle`
.MuiTooltip-tooltip {
    padding: 12px;
    background: rgba(0,0,0,0.7);
    .MuiTooltip-arrow  {
        color: rgba(0,0,0,0.7);
    }
}
* {
    
font-family: 'Poppins',' PingFang SC' !important;
    ::-webkit-scrollbar {
        width: 8px;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 8px;
    }
  }
`;

export default function ThemeProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const darkMode = useIsDarkMode();

    const themeObject = useMemo(() => theme(darkMode), [darkMode]);

    return (
        <StyledComponentsThemeProvider theme={themeObject}>
            {children}
        </StyledComponentsThemeProvider>
    );
}

interface TypeProps extends TextProps {
    ellipsis?: boolean;
    wordBreak?: boolean;
}

const TextWrapper = styled(Text)<TypeProps>`
    color: ${({ color, theme }) => (theme.c as any)[color as string]};
    width: ${({ ellipsis, wordBreak }) =>
        ellipsis || wordBreak ? '100%' : 'unset'};
    overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'unset')};
    text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'unset')};
    white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'unset')};
    word-break: ${({ wordBreak }) => (wordBreak ? 'break-all' : 'unset')};
`;

export const TYPE = {
    main(props: TypeProps) {
        return (
            <TextWrapper
                fontWeight={500}
                fontSize={16}
                color="t01"
                {...props}
            />
        );
    },
    desc(props: TypeProps) {
        return (
            <TextWrapper
                fontWeight={500}
                fontSize={14}
                color="t02"
                {...props}
            />
        );
    },
};
