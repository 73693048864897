import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Row, { RowBetween } from 'components/Row';
import { Info } from 'components/Text';
import Button from 'components/Button';
import BigNumber from 'bignumber.js';
import NextImg from 'assets/images/next.png';
import TokenList from 'components/Tokenlist';
import { Layer, Token, TokenConfig } from '../../../constants';
import { useAppSelector } from 'state/hooks';
import AddToken from 'components/AddTokenToWallet';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { TYPE } from 'theme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Wrapper = styled.div`
    border-radius: 4px;
    background: transparent;
    width: 100%;
    box-sizing: border-box;

    .input {
        background: transparent;
        flex-grow: 1;
        max-width: 310px;
        height: 30px;
        outline: none;
        border: none;
        color: ${({ theme }) => theme.c.t01};
        font-size: 36px;
        font-weight: bold;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::placeholder {
            color: ${({ theme }) => theme.c.t02};
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
     .line {
          flex-wrap: wrap;
      }

      .input {
          min-width: 20%;
          flex-shrink: 1;
          height: 100%;
      }

    `}
`;

const StyledTokenInfoRow = styled(RowBetween)`
    width: 100%;
    height: 72px;
    box-sizing: border-box;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
    background: ${({ theme }) => theme.c.bg04};

    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 60px;

    .balance {
        font-size: 12px !important;
    }
    `}
`;

const TokenListButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding: 6px; */
    box-sizing: border-box;
    height: 56px;
    border-radius: 20px;
    /* background: #f2f4f7; */
    cursor: pointer;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height:  48px;
    `}
    img {
        &.logo {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            border-radius: 14px;
        }
    }
`;

const StyledArrowDropDownIcon = styled.img`
    width: 10px;
    height: 7px;
    color: #00cfff;
    margin-left: 8px;

    .loadingIcon {
        font-size: 16px !important;
        color: #fff !important;
    }
`;

const MaxButton = styled(Button)`
    width: 64px;
    height: 36px;
    background: #07ddd0;
    border-radius: 15px !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    box-shadow: 3px 7px 15px -3px rgba(0, 158, 169, 0.3) !important;
`;

const InputRow = styled(Row)`
    width: 100%;
    margin-top: 24px;
    height: 100px;
    align-items: center;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 24px;
    background: ${({ theme }) => theme.c.bg04};
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 75px;
    padding: 8px 16px;
    margin-top: 8px;
    `}/* border-bottom: 1px solid rgba(7, 221, 208, 0.2); */
`;

interface Props {
    network: string;
    balance: BigNumber;
    value: BigNumber;
    onChange: (value: BigNumber) => void;
    disabled?: boolean;
    hideMax?: boolean;
    hideAddToken?: boolean;
    onError?(e?: string): void;
    isSearch?: boolean;
    layer?: Layer;
    token: Token;
    onSelectToken?(name: Token): void;
    handleMax?(): void;
    getLeftAmount?(): Promise<BigNumber | undefined>;
}

export default function NumberInput({
    network,
    balance,
    value,
    onChange,
    disabled,
    hideMax,
    handleMax = () => {},
    onError,
    token,
    layer = Layer.layer1,
    onSelectToken,
    hideAddToken,
    getLeftAmount,
}: Props) {
    const [input, setInput] = useState('');
    const [isOpenTokenList, setIsOpenTokenList] = useState(false);
    const [leftAmount, setLeftAmount] = useState<BigNumber>();
    const { tokens } = useAppSelector((state) => state.tokens);
    const mainnetToken = layer === Layer.layer1 ? Token.eth : Token.metis;

    const mainnetTokenDetail = useMemo(() => {
        if (tokens[mainnetToken]) {
            return tokens[mainnetToken][layer];
        }
        return undefined;
    }, [layer, tokens[mainnetToken]]);

    useEffect(() => {
        if (onError) {
            if (value.isGreaterThan(balance)) {
                onError('Insufficient Balance');
            } else if (
                value.isGreaterThan(0) &&
                layer === Layer.layer2 &&
                mainnetTokenDetail &&
                leftAmount
            ) {
                if (
                    (token !== Token.metis &&
                        mainnetTokenDetail.balance.isLessThan(leftAmount)) ||
                    (token === Token.metis &&
                        mainnetTokenDetail.balance
                            .minus(value)
                            .isLessThan(leftAmount))
                ) {
                    onError(
                        `You should reserve at least ${
                            leftAmount?.toFixed() || '-'
                        } Metis as the bridging cost to Ethereum.`,
                    );
                } else {
                    onError();
                }
            } else {
                onError();
            }
        }
    }, [value, balance, onError, mainnetTokenDetail, layer, leftAmount]);

    async function getLeftAmountFunc() {
        if (!getLeftAmount) return;
        const res = await getLeftAmount();
        setLeftAmount(res);
    }

    useEffect(() => {
        if (!new BigNumber(input || 0).isEqualTo(value)) {
            setInput(value.toNumber() ? value.toFixed() : '');
            getLeftAmountFunc();
        }
    }, [value, input]);

    async function handleMaxFunc() {
        if (disabled) return;

        await handleMax();
    }

    const tokenDetail = useMemo(() => {
        return tokens[token] ? tokens[token][layer] : undefined;
    }, [tokens, layer, token]);

    const isMainnetCoin =
        (layer === Layer.layer1 && token === Token.eth) ||
        (layer === Layer.layer2 && token === Token.metis);

    return (
        <>
            <Wrapper>
                <StyledTokenInfoRow
                    onClick={() => {
                        setIsOpenTokenList(true);
                    }}
                >
                    <TokenListButtonWrapper>
                        <img
                            src={TokenConfig[token].img}
                            alt=""
                            className={'logo'}
                        />
                        <TYPE.main fontSize={14} fontWeight={600}>
                            {TokenConfig[token][layer].symbol}
                        </TYPE.main>
                        <StyledArrowDropDownIcon
                            src={require('assets/images/dropdown.png')}
                        />
                    </TokenListButtonWrapper>
                    <TYPE.main
                        className="balance"
                        fontSize={14}
                        color="#A2A2A8"
                    >
                        Balance: &nbsp;&nbsp;
                        {tokenDetail
                            ? tokenDetail.balance.toFixed(3)
                            : '0'}{' '}
                        {TokenConfig[token][layer].symbol}
                    </TYPE.main>
                </StyledTokenInfoRow>
                <InputRow align="between">
                    <input
                        type="number"
                        placeholder="0.00"
                        className={'input'}
                        value={input}
                        onChange={(e) => {
                            setInput(e.currentTarget.value);
                            onChange(
                                new BigNumber(`${e.currentTarget.value}` || 0),
                            );
                        }}
                        disabled={disabled}
                    />
                    {!hideMax && (
                        <MaxButton
                            onClick={handleMaxFunc}
                            loadingProps={{
                                classname: 'loading',
                                size: 16,
                            }}
                            showLoading
                        >
                            MAX
                        </MaxButton>
                    )}
                </InputRow>
                {!hideAddToken && !isMainnetCoin && (
                    <Row align="between">
                        <span></span>
                        <AddToken token={token} />
                    </Row>
                )}
            </Wrapper>
            <TokenList
                isOpen={isOpenTokenList}
                layer={layer}
                onClose={() => {
                    setIsOpenTokenList(false);
                }}
                onSelectToken={(name: Token) => {
                    if (onSelectToken) {
                        onSelectToken(name);
                    }
                }}
            />
        </>
    );
}
