import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsDark } from 'state/user';

export function useToggleTheme() {
    const dispatch = useAppDispatch();

    const { isDark } = useAppSelector((state) => state.user);
    function toggle() {
        dispatch(setIsDark(!isDark));
    }

    return toggle;
}
