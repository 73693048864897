// 请求过期时间
export const REQUEST_TIMEOUT_MILLISECONDS = 30 * 1000;
// 请求成功
export const REQUEST_STATUS_SUCCESS = 200;
export const REQUEST_DATA_STATUS_SUCCESS = 1;
export const REQUEST_STATUS_SUCCESS_LOWER_LIMIT = 200;
export const REQUEST_STATUS_SUCCESS_UPPER_LIMIT = 200;

// 请求错误
export const REQUEST_STATUS_ERROR = 0;
