import React, { useCallback, useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import style from './index.module.scss';
import cx from 'classnames';
import styled from 'styled-components';

const StyledButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    color: #000;

    &.middle {
        padding: 15px;
        font-size: 16px;
        border-radius: 8px;
    }

    &.normal {
        padding: 15px 20px;
        font-size: 22px;
        border-radius: 8px;
    }

    &.primary {
        background-color: #00cfff;
        box-shadow: 3px 7px 15px -3px rgba(0, 158, 169, 0.73);
        color: #000;
    }

    &.transparent {
        background: transparent;
        color: #00cfff;

        &:hover,
        &:focus,
        &:active {
            color: #00cfff;
        }
    }

    &.disabled {
        background: #dad8d8;
        border-image: none;
        box-shadow: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            background: #dad8d8;
            border-image: none;
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.loading {
        color: transparent !important;
    }

    .loadingIcon {
        position: absolute;
        // top: 50%;
        // left: 50%;
        // margin-top: -50%;
        // margin-left: -50%;
        color: #000;
    }
`;
interface Props {
    size?: 'middle' | 'normal';
    type?: 'primary' | 'transparent';
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    showLoading?: boolean;
    loadingProps?: {
        classname?: string;
        size?: number;
    };
    onClick?:
        | ((event?: React.SyntheticEvent) => void)
        | ((event?: React.SyntheticEvent) => Promise<any>);
}

export default function Button(props: Props) {
    const {
        size = 'normal',
        type = 'primary',
        disabled,
        children,
        className,
        onClick,
        showLoading = false,
        loadingProps = {
            classname: '',
            size: 24,
        },
        ...rest
    } = props;
    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(loading);
    const mountedRef = useRef(true);

    loadingRef.current = loading;

    const handleLoading = useCallback(
        (value: any) => {
            if (mountedRef.current) {
                setLoading(value);
            }
        },
        [setLoading],
    );

    const handleClick = useCallback(
        (event: any) => {
            if (loadingRef.current || disabled || !onClick) {
                return;
            }

            const promise = onClick(event);
            if (
                promise != null &&
                typeof (promise as Promise<any>).then === 'function'
            ) {
                handleLoading(true);
                (promise as Promise<any>)
                    .then((result: any) => {
                        handleLoading(false);
                        return result;
                    })
                    .catch((e) => {
                        handleLoading(false);
                    });
            }
        },
        [loadingRef, mountedRef, disabled, handleLoading, onClick],
    );

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const classes = [
        size === 'normal' && 'normal',
        size === 'middle' && 'middle',
        type === 'primary' && 'primary',
        type === 'transparent' && 'transparent',
        disabled && 'disabled',
        loading && showLoading && 'loading',
        className,
    ];

    return (
        <StyledButton
            className={classes.join(' ')}
            onClick={handleClick}
            {...rest}
        >
            {children}
            {showLoading && loading && (
                <CircularProgress
                    size={loadingProps.size}
                    className={cx('loadingIcon', loadingProps.classname)}
                />
            )}
        </StyledButton>
    );
}
