import { Env, Layer, RPC } from '../constants';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useEffect, useState } from 'react';
import Web3 from 'web3';

export default function useContract(address: string, abi: any) {
    const { web3 } = useAppSelector((state) => state.user);
    const [instance, setInstance] = useState();

    useEffect(() => {
        if (web3) {
            const instance = new web3.eth.Contract(abi, address);
            setInstance(instance);
        }
    }, [web3]);

    return instance;
}

export function useContractByGetContract(getContractFunc: any) {
    const { web3, chainId } = useAppSelector((state) => state.user);
    const [contract, setContract] = useState<any>();

    useEffect(() => {
        if (getContractFunc && web3 && chainId) {
            const contractInstance = getContractFunc(chainId, web3);
            setContract(contractInstance);
        } else {
            setContract(undefined);
        }
    }, [getContractFunc, web3, chainId]);

    return contract;
}

export function useGetContractByUserWeb3() {
    const { web3 } = useAppSelector((state) => state.user);
    function getContract(address: string, abi: any) {
        if (web3) {
            const instance = new web3.eth.Contract(abi, address);
            return instance;
        } else {
            return undefined;
        }
    }

    return getContract;
}

export function getWeb3ByEnvLayer(env: Env, layer: Layer) {
    const rpc = RPC[env][layer];
    const web3 = new Web3(new Web3.providers.HttpProvider(rpc));
    return web3;
}

export function getContractByEnvLayer(
    address: string,
    abi: any,
    env: Env,
    layer: Layer,
) {
    const web3 = getWeb3ByEnvLayer(env, layer);
    if (web3) {
        const instance = new web3.eth.Contract(abi, address);
        return instance;
    } else {
        return undefined;
    }
}
