/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useCallback, useEffect } from 'react';
import {
    setAddress,
    setConnected,
    setEmail,
    setIsPolis,
    setName,
} from './index';
import { setIsOpenConnectModal } from 'state/user';
import storage from 'utils/storage';
import { USER_INFO, AUTH_PARAMS } from '../../constants/storage';
import { setClient, setEnv, setLayer } from '../metisMiddlewareClient';
import {
    ChainId,
    Env,
    Layer,
    TestnetTokenList,
    TokenList,
} from '../../constants';
import { setTokenList } from '../tokens';

export function clearUserLoginInfoHooks() {
    const dispatch = useAppDispatch();

    const clear = useCallback(() => {
        console.log('clear');
        dispatch(setConnected(false));
        dispatch(setIsPolis(false));

        dispatch(setName(''));
        dispatch(setAddress(''));
        dispatch(setEmail(''));
        dispatch(setClient(undefined));
        storage.remove(USER_INFO);
        storage.remove(AUTH_PARAMS);
    }, [dispatch]);

    return clear;
}

export function setIsOpenConnectModalHooks() {
    const dispatch = useAppDispatch();

    const setIsOpen = useCallback(
        (isOpen = false) => {
            dispatch(setIsOpenConnectModal(isOpen));
        },
        [dispatch],
    );

    return setIsOpen;
}

export default function UserHooks() {
    const dispatch = useAppDispatch();

    const { isPolis, chainId } = useAppSelector((state) => state.user);

    useEffect(() => {
        if (!isPolis && chainId) {
            if (
                chainId === ChainId.mainnet ||
                chainId === ChainId.layer2Mainnet
            ) {
                dispatch(setEnv(Env.mainnet));
                dispatch(setTokenList(TokenList));
            } else {
                dispatch(setEnv(Env.test));
                dispatch(setTokenList(TestnetTokenList));
            }

            if (chainId === ChainId.mainnet || chainId === ChainId.testnet) {
                dispatch(setLayer(Layer.layer1));
            } else {
                dispatch(setLayer(Layer.layer2));
            }
        }
    }, [isPolis, chainId]);
}

export function useIsDarkMode(): boolean {
    const { isDark } = useAppSelector((state) => state.user);

    return isDark;
}
