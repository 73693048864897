import { metisSepolia } from './metisSepoliachain';
export const projectId = 'b5605b05126c8a25b9b8bddfa5379db1';
import { getDefaultConfig, getDefaultWallets } from '@rainbow-me/rainbowkit';
import {
    argentWallet,
    imTokenWallet,
    ledgerWallet,
    okxWallet,
    oneKeyWallet,
    trustWallet,
    uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { andromeda } from './metisChain';
import { mainnet, sepolia } from 'viem/chains';

const { wallets: defaultWallets } = getDefaultWallets();
const wallets = [
    ...defaultWallets,
    {
        groupName: 'Other',
        wallets: [
            argentWallet,
            trustWallet,
            ledgerWallet,
            okxWallet,
            imTokenWallet,
            uniswapWallet,
            oneKeyWallet,
        ],
    },
];

const chainConfig = {
    chains: [mainnet, sepolia, andromeda, metisSepolia],
    appName: 'Metis Bridge Protocol',
    projectId: projectId,
    wallets,
};

export default getDefaultConfig(chainConfig as any);
