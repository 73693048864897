import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';
import { AutoColumn } from '../Column';
import { TYPE } from 'theme';
import Button from '../Button';
import Row from '../Row';
import { useAppSelector } from 'state/hooks';
import { Env } from '../../constants';
import { ReactComponent as LinkSVG } from 'assets/images/activity/ic_link.svg';

interface Props {
    isOpen: boolean;
    onClose(): void;
}

const StyledInfoImg = styled.img`
    margin: 0 auto;
    margin-top: 26px;
    width: 72px;
    height: auto;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100px;
    `}
`;

const StyleLink = styled.a`
    color: ${({ theme }) => theme.c.t03};
    font-size: 16px;
`;

const StyledAutoColumn = styled(AutoColumn)`
    width: 536px;
    padding: 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
    `}
`;

const StyledButton = styled(Button)`
    width: 200px;
    height: 48px;
    border-radius: 8px;
    margin-top: 30px;
`;

const PowerBy = styled.div`
    display: flex;
    background: #040817;
    border-radius: 8px;
    padding: 12px 8px;
    align-items: center;
    cursor: pointer;

    img {
        width: 61px;
        height: auto;
        margin-left: 8px;
    }
`;

const BuyRow = styled.div`
    padding: 12px;
    padding-right: 20px;
    background: ${({ theme }) => theme.c.bg04};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    height: 64px;
    cursor: pointer;
`;

const BuyRowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 12px;
    img {
        width: 40px;
        height: 40px;
    }
`;
const LinkIcon = styled(LinkSVG)`
    color: ${({ theme }) => theme.c.t02};
    width: 14px;
    height: 14px;
`;

export default function BanxaDisclaimerModal({ isOpen, onClose }: Props) {
    const { env } = useAppSelector((state) => state.metisMiddlewareClient);
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <StyledAutoColumn gap="24px">
                <StyledInfoImg
                    src={require('assets/images/activity/img_read carefully@2x.png')}
                />
                <TYPE.main fontSize={16} textAlign="center" fontWeight={600}>
                    Read Carefully
                </TYPE.main>
                <TYPE.main fontSize={14} textAlign={'center'}>
                    Clicking the links below will open separate web pages
                    managed by our partners, which are not operated by Metis.
                </TYPE.main>

                {/* <Row justify="center">
                    <StyledButton
                        onClick={() => {
                            window.open(
                                `${
                                    env === Env.mainnet
                                        ? 'https://metis.banxa.com'
                                        : 'https://metis.banxa-sandbox.com'
                                }/?fiatType=USD&blockchain=METIS `,
                            );
                            onClose();
                        }}
                    >
                        Buy
                    </StyledButton>
                </Row> */}
                <BuyRow
                    onClick={() => {
                        window.open(
                            'https://metis.banxa.com/?fiatType=USD&blockchain=METIS',
                        );
                    }}
                >
                    <BuyRowLeft>
                        <img
                            src={require('assets/images/activity/logo_banxa@2x.png')}
                            alt=""
                        />
                        <AutoColumn gap="4px" justify="flex-start">
                            <TYPE.main
                                fontSize={14}
                                textAlign={'center'}
                                fontWeight={600}
                            >
                                Banxa
                            </TYPE.main>
                            <TYPE.desc fontSize={14} textAlign={'center'}>
                                banxa.com
                            </TYPE.desc>
                        </AutoColumn>
                    </BuyRowLeft>
                    <LinkIcon />
                </BuyRow>
                <BuyRow
                    onClick={() => {
                        window.open(
                            'https://ramp.alchemypay.org/?crypto=METIS&network=ETH',
                        );
                    }}
                >
                    <BuyRowLeft>
                        <img
                            src={require('assets/images/activity/logo_Alchemy Pay@2x.png')}
                            alt=""
                        />
                        <AutoColumn gap="4px" justify="flex-start">
                            <TYPE.main
                                fontSize={14}
                                textAlign={'center'}
                                fontWeight={600}
                            >
                                Alchemy Pay
                            </TYPE.main>
                            <TYPE.desc fontSize={14} textAlign={'center'}>
                                alchemypay.org
                            </TYPE.desc>
                        </AutoColumn>
                    </BuyRowLeft>
                    <LinkIcon />
                </BuyRow>

                {/* <Row justify="center" marginTop={30}>
                    <PowerBy
                        onClick={() => {
                            window.open('https://banxa.com/');
                        }}
                    >
                        <TYPE.desc fontSize={12}>Powered by</TYPE.desc>
                        <img
                            src={require('assets/images/activity/banxa-logo.png')}
                            alt=""
                        />
                    </PowerBy>
                </Row> */}
                {/* <TYPE.desc fontSize={12}>
                    You agree that you are accessing Banxa’s website or service
                    at your own risk and that we do not assume any resposibility
                    for, and you relieve us fron any liability from your use of,
                    Banxa’s website, information, material, products or
                    services. Please read and agree to Banxa’s&nbsp;
                    <StyleLink
                        href="https://banxa.com/wp-content/uploads/2022/10/Customer-Terms-and-Conditions-1-July-2022.pdf"
                        target="_blank"
                    >
                        Terms of Use
                    </StyleLink>
                    &nbsp; prior to using their products or services. For any
                    questions related to Banxa’s products or serices, please
                    contact Banxa via&nbsp;
                    <StyleLink href="mailto:support@banxa.com">
                        support@banxa.com.
                    </StyleLink>
                </TYPE.desc> */}
            </StyledAutoColumn>
        </Modal>
    );
}
