import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Homepage from 'pages/homepage';
// import Welcome from 'pages/welcome';
import History from 'pages/homepage/history';
// import Forbiden403 from 'pages/403';
import Footer from 'components/Footer';
import PresetAuth from 'hooks/use-preset-auth';
import { useAppSelector } from 'state/hooks';
import { setIsOpenConnectModalHooks } from 'state/user/hooks';
import ConnectWalletModal from 'components/ConnectWallet';
import Connecting from 'components/Loading/ConnectingLoading';
import TokensHooks from 'state/tokens/hooks';
import UserHooks from 'state/user/hooks';
import styled from 'styled-components';
import UseWalletConnectHook from 'hooks/use-walletconnect';

const Container = styled.div`
    width: 100%;
    // box-sizing: border-box;
    // padding-top: 93px;
    min-height: 100vh;
    overflow-y: scroll;
    /* background: url('./assets/images/bg.png') no-repeat; */
    background-size: 100% 100%;

    &.iframeContent {
        overflow-y: unset;

        .mainView {
            padding-top: 80px;
        }
    }
`;

const MainView = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background: url(${({ theme }) =>
        theme.isDark
            ? require('assets/images/page-bg-dark.png')
            : require('assets/images/page-bg.png')});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    color: white;
    padding-top: 120px;
    padding-bottom: 130px;
    z-index: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
     padding-top: 100px;
     padding-bottom: 165px;
    `}
`;

function App() {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setReady(true);
    }, []);
    const { i18n } = useTranslation();
    const user = useAppSelector((state) => state.user);
    const location = useLocation();
    // const history = useHistory();

    const setIsOpenConnectModal = setIsOpenConnectModalHooks();

    useEffect(() => {
        if (i18n.language === 'ru') {
            document.getElementsByTagName('html')[0].className = 'ru';
        }
    }, []);

    // useEffect(() => {
    //     axios.get(`https://ipapi.co/json/`).then((data) => {
    //         if (data.status === 200 && data.data) {
    //             console.log('countryInfo:', data.data);
    //             if (data.data.country_code === 'US') {
    //                 history.replace('/403');
    //             }
    //         }
    //     });
    // }, [history]);

    TokensHooks();
    UserHooks();
    const isIframeLink = location && location.pathname === '/banxa';
    const is403 = location && location.pathname === '/403';

    return (
        <>
            <PresetAuth />
            <UseWalletConnectHook />
            <Container className={isIframeLink ? 'iframeContent' : ''}>
                {!is403 && <Header />}
                <MainView className="mainView">
                    <Switch>
                        <Route path="/home">
                            <Homepage />
                        </Route>
                        <Route exact path="/welcome">
                            <Redirect to="/home" />
                        </Route>
                        <Route path="/history">
                            <History />
                        </Route>
                        {/* <Route path="/403">
                <Forbiden403 />
            </Route> */}

                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                    </Switch>
                    {!is403 && <Footer />}
                </MainView>
            </Container>
            <ConnectWalletModal
                isOpen={user.isOpenConnectModal}
                onClose={() => {
                    setIsOpenConnectModal(false);
                }}
            />
            <Connecting loading={user.loadingUserInfo} />
        </>
    );
}

export default App;
